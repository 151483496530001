html, body
{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

p
{
  margin: 0px;
  padding: 0px;
}

.page *
{
  font-family: "Titillium";
}

.bread-crumbs *
{
  font-family: "Titillium";
}

@font-face
{
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/material-ui-icons.woff2') format('woff2');
}

@font-face {
  font-family: Titillium;
  font-weight: 100;
  src: url('../fonts/TitilliumWeb-Light.ttf');
}

@font-face {
  font-family: Titillium;
  font-weight: bold;
  src: url('../fonts/TitilliumWeb-Bold.ttf');
}

@font-face {
  font-family: TitilliumBold;
  src: url('../fonts/TitilliumWeb-Bold.ttf');
}

.material-icons
{
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

.header-logo-image
{
  height: 60px;
  max-width: 100%;
}

.header-logo-image-menu
{
  height: 40px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.page
{
  position: absolute;
  top: 0px;
  left: 0px;
  height: auto;
  width: 100%;
  background-color: #e5e5e5;
}

.footer
{
  background-color: #e5e5e5;
  height: auto;
  width: 100%;
  position: relative;
  float: left;
}

.open-parralax
{
  height: 100vh;
  width: 100%;
  position: relative;
  float: left;
}

.open-parralax-container
{
  position: relative;
  top:0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100vw;
  height: 100vh;
}

.page-content
{
  position: relative;
  float: left;
  height: auto;
  border-radius: 6px;
  background-color: white;
  min-height: 500px;
  width: calc(100% - 40px);
  margin: 25px;
  margin-left: 20px;
  margin-right: 20px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  top: -125px;
  z-index: 600;
}

.grid-section
{
  padding: 70px;
  text-align: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

.open-parralax-content-title
{
  text-shadow: 2px 2px 5px black;
  color:white;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  font-family: "TitilliumBold";
}


@media only screen and (max-width: 800px)
{
  .page-content
  {
    border-radius: 0px;
    width: 100%;
    margin: 0px;
    top: -100px;
  }

  .grid-section
  {
    padding-left: 15px;
    padding-right: 15px;
  }

  .open-parralax-content-title
  {
    font-size: 26px;
  }
}

.home-page-welcome
{
  color: #999;
  text-align: center;
}

.grid-title
{
  color: #3C4858;
  margin-top: 30px;
  min-height: 32px;
  font-weight: bold;
  margin-bottom: 2rem;
  text-decoration: none;
  font-size: 35px;
}

.footer-title
{
  color: #3C4858;
  margin-top: 30px;
  min-height: 32px;
  font-weight: bold;
  margin-bottom: 2rem;
  text-decoration: none;
  font-size: 18px;
  margin-left: 40px;
  margin-bottom: 5px;
}

.grid-partner
{
  color: #999;
  font-size: 17px;
  text-align: center;
  font-weight:100;
  line-height: 26px;
  max-width: 400px;
  margin-bottom: 100px;
}

.grid-partner img
{
  max-width: 100%;
}


.grid-description
{
  color: #999;
  font-size: 17px;
  text-align: center;
  font-weight:100;
  line-height: 26px;
}

.open-parralax-content
{
  background: transparent;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  user-select: none;
}

.post-content
{
    margin-top: 80px;
}

.MuiDrawer-paper
{
  background-color: black !important;
  color: white !important;
}

.mouse-scroll
{
  position: absolute;
  margin: auto;
  left: 50%;
  top: 20px;
  -webkit-transform: translateX(-50%);
  z-index: 10;
}

.mouse-scroll span
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
       transform: rotate(45deg);
       transform: rotate(45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  margin: 0 0 3px 5px;
}

.mouse-scroll .mouse
{
  height: 21px;
  width: 14px;
  border-radius: 10px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  border: 2px solid #000;
  top: 170px;
}

.mouse-scroll .down-arrow-1
{
  margin-top: 6px;
}

.mouse-scroll .down-arrow-1, .mouse-scroll .down-arrow-2, .mouse-scroll .down-arrow-3
{
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
}

.mouse-croll .down-arrow-1
{
   -webkit-animation-delay: .1s;
   -moz-animation-delay: .1s;
   -webkit-animation-direction: alternate;
}

.mouse-scroll .down-arrow-2
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
}

.mouse-scroll .down-arrow-3
{
   -webkit-animation-delay: .3s;
   -moz-animation-dekay: .3s;
   -webkit-animation-direction: alternate;
}

.mouse-scroll .mouse-in
{
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: #000;
  position: relative;
}

.mouse-scroll .mouse-in
{
  animation: animated-mouse 1.2s ease infinite;
 -webkit-animation: animated-mouse 1.2s ease infinite;
 -moz-animation: animated-mouse 1.2s ease infinite;
}

@keyframes animated-mouse {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
     opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}

@keyframes mouse-scroll {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.info-area
{
  max-width: 360px;
  margin: 0 auto;
  padding: 0px;
  margin-bottom: 30px;
}


.info-area-link
{
  text-decoration: none;
}

.post-area
{
  max-width: 360px;
  margin: 0 auto;
  padding: 10px;
}

.employees-area
{
  max-width: 360px;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 40px;
}

.online-list-area
{
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 60px;
}

.opening-hours-area
{
  width: calc(100% - 70px);
  margin: 0 auto;
  padding: 10px;
  -webkit-box-shadow: inset 5px 5px 50px -40px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 5px 5px 50px -40px rgba(0,0,0,0.75);
  box-shadow: inset 5px 5px 50px -40px rgba(0,0,0,0.75);
  margin: 25px;
  padding-top: 20px;
}

.opening-hours-time-wrapper
{
  margin: auto;
  padding: 0;
  z-index: 1;
  position: relative;
  margin-bottom: 20px;
}

.opening-hours-name-wrapper
{
  margin: auto;
  padding: 0;
  z-index: 1;
  position: relative;
  margin-top: 20px;
}

.opening-hours-break
{
  background-color: #3c4858;
  margin: auto;
  width: 50px;
  height: 2px;
  border: 2px;
}

.popout-info-time-break
{
  background-color: #3c4858;
  margin: auto;
  width: 50px;
  height: 2px;
  border: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.opening-hours-time
{
  font-size: 25px;
  text-align: center;
  font-family: "TitilliumBold";
  color: #6F7277;
}

.opening-hours-time-and
{
  font-size: 14px;
  text-align: center;
  font-family: "TitilliumBold";
  color: #6F7277;
  margin-top: 10px;
  margin-bottom: 10px;
}

.opening-hours-name
{
  font-size: 18px;
  text-align: center;
  color: #3c4858;
  padding: 5px;
  margin: 0px !important;
}

.online-list-area-image-wrapper
{
  margin: auto;
  padding: 0;
  z-index: 1;
  height: 80px;
  width: 80px;
  position: relative;
}

.online-list-area-checkbox
{
  width: 50px;
  height: 50px;
  margin: auto;
}

.employees-area-image-wrapper
{
  margin: auto;
  padding: 0;
  z-index: 1;
  height: 150px;
  width: 150px;
  position: relative;
  object-fit: cover;
}

.post-area-image-wrapper
{
  margin: 0 !important;
  padding: 0;
  z-index: 1;
  position: relative;
}

.post-extended-area
{
  margin: 0 auto;
  height: auto;
  padding: 10px;
  margin-bottom: 50px;
}

.opening-break
{
  margin: auto;
  width: 200px;
}

.pop-out-info
{
  position: fixed;
  height: auto;
  width: 300px;
  z-index: 800;
  transform: translate(0px, -50%);
  min-height: 50px;
  top:50%;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 50px 1fr;
  display: flex;
  align-items: center;
  transition: .3s ease;
}

@media only screen and (max-width: 600px)
{
  .turn-post .MuiGrid-container
  {
    flex-direction: column-reverse;
  }

  .opening-break
  {
    margin: auto;
    width: 200px;
    margin-top: 50px;
    margin-bottom: 50px;
  }


  .pop-out-info
  {
    transform: translate(0px, -100%);
    top:100%;
    align-items: flex-end;
  }
}

.service-extended-area
{
  max-height: 420px;
  margin: 0 auto;
  height: auto;
  padding: 10px;
  margin-bottom: 20px;
}

.info-area-icon-wrapper
{
  width: 100px;
  height: 100px;
  pointer-events: none;
  padding: 0;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  position: relative;
  margin: auto;
}

.info-area-icon
{
  width: 100%;
  height: 100%;
  pointer-events: none;
  margin: 0 !important;
  padding: 0;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 150px;
  object-fit: cover;
}

.info-area-icon-cover
{
  top: 12px;
  width: 100%;
  filter: blur(12px);
  height: 100%;
  opacity: 1;
  z-index: -1;
  transform: scale(0.94);
  transition: opacity .45s;
  position: absolute;
  top:0px;
  left: 0px;
  background-size: cover;
  border-radius: 150px;
}

.online-list-area-image
{
  width: 100%;
  height: 100%;
  pointer-events: none;
  margin: 0 !important;
  padding: 0;
  object-fit: contain;
}

.online-list-image-cover
{
  top: 12px;
  width: 100%;
  filter: blur(12px);
  height: 100%;
  opacity: 1;
  z-index: -1;
  position: absolute;
  transform: scale(0.94);
  transition: opacity .45s;
  background-size: cover;
  border-radius: 150px;
}

.employees-area-image
{
  width: 100%;
  height: 100%;
  pointer-events: none;
  margin: 0 !important;
  padding: 0;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 150px;
  object-fit: cover;
}

.employees-area-image-cover
{
  top: 12px;
  width: 100%;
  filter: blur(12px);
  height: 100%;
  opacity: 1;
  z-index: -1;
  position: absolute;
  transform: scale(0.94);
  transition: opacity .45s;
  background-size: cover;
  border-radius: 150px;
}

.post-extended-area-image
{
  height: auto;
  pointer-events: none;
  margin: 0 !important;
  padding: 0;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  max-width: 100%;
  object-fit: cover;
}

.post-extended-area-image-cover
{
  top: 12px;
  width: 100%;
  filter: blur(12px);
  height: 100%;
  opacity: 1;
  z-index: -1;
  position: absolute;
  transform: scale(0.94);
  transition: opacity .45s;
  background-size: cover;
}

.service-extended-area-image
{
  height: auto;
  pointer-events: none;
  margin: 0 !important;
  padding: 0;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  width: 180px;
  height: 180px;
  object-fit: cover;
}

.service-extended-area-image-cover
{
  top: 12px;
  filter: blur(12px);
  border-radius: 100px;
  width: 200px;
  height: 200px;
  opacity: 1;
  z-index: -1;
  position: absolute;
  transform: scale(0.94);
  transition: opacity .45s;
  background-size: cover;
}

.service-extended-area-image-wrapper
{
  margin: 0 !important;
  padding: 0;
  z-index: 1;
  position: relative;
  width: 180px;
  height: 180px;
}

.service-extended-area-title
{
  color: #3C4858;
  margin: 0.875rem 0 0.875rem;
  text-decoration: none;
  font-weight: 700;
  font-size: 26px;
  text-align: left;
}

.service-extended-area-button-wrapper
{
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
}

.service-extended-area-image-cover
{
  top: 12px;
  width: 100%;
  filter: blur(12px);
  height: 100%;
  opacity: 1;
  z-index: -1;
  position: absolute;
  transform: scale(0.94);
  transition: opacity .45s;
  background-size: cover;
}

.post-extended-area-image-wrapper
{
  margin: 0 !important;
  padding: 0;
  z-index: 1;
  position: relative;
  max-width: 100%;
}

.post-extended-area-title
{
  color: #3C4858;
  margin: 0.875rem 0 0.875rem;
  text-decoration: none;
  font-weight: 700;
  font-size: 26px;
  text-align: left;
}

.post-extended-area-button-wrapper
{
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
}

.post-area-button-wrapper
{
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.post-area-button-wrapper
{
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.post-area-button-wrapper .MuiButton-label
{
  font-weight: bold;
}

.post-area-image
{
  width: 100%;
  pointer-events: none;
  margin: 0 !important;
  padding: 0;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  object-fit: cover;
}

.post-area-image-cover
{
  top: 12px;
  width: 100%;
  filter: blur(12px);
  height: 100%;
  opacity: 1;
  z-index: -1;
  position: absolute;
  transform: scale(0.94);
  transition: opacity .45s;
  background-size: cover;
}

.info-area-description-wrapper
{
  color: gray;
  overflow: hidden;
}

.online-list-area-description-wrapper
{
  text-align: left;
  color: gray;
  overflow: hidden;
}

.post-area-description-wrapper
{
  color: gray;
  overflow: hidden;
}

.employees-area-description-wrapper
{
  color: gray;
  overflow: hidden;
}

.employees-area-name
{
  color: #3C4858;
  margin: 1.75rem 0 0.875rem;
  text-decoration: none;
  font-family: "TitilliumBold";
  font-size: 25px;
}

.online-list-area-name
{
  color: #3C4858;
  margin: 0px;
  text-decoration: none;
  font-family: "TitilliumBold";
  font-size: 20px;
}

.employees-area-position
{
  color: gray;
  overflow: hidden;
  margin-top: 0px;
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: bold;
}

.employees-area-job
{
  color: gray;
  overflow: hidden;
  margin-top: 0px;
  font-size: 17px;
  margin-bottom: 20px;
}

.employees-area-description
{
  color: gray;
  overflow: hidden;
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: 25px;
}

.info-area-title
{
  color: #3C4858;
  margin: 1.75rem 0 0.875rem;
  text-decoration: none;
  font-weight: 700;
}

.info-area-description
{
  color: gray;
  overflow: hidden;
  margin-top: 0px;
  font-size: 16px;
}

.post-area-title
{
  color: #3C4858;
  margin: 1.75rem 0 0.875rem;
  text-decoration: none;
  font-weight: 700;
  text-align: left;
}

.post-area-description
{
  color: gray;
  overflow: hidden;
  margin-top: 0px;
  font-size: 16px;
  text-align: left;
}

.footer-logo
{
  width: auto;
  height: auto;
  margin-bottom: 10px;
}

.footer-box
{
  margin-left: 50px;
  width: calc(100% - 50px);
  margin-top: 10px;
  user-select: text;
}

.footer-box p
{
  margin: 0px;
}

.footer-box a
{
  color: rgb(20, 160, 219);
  text-decoration: none;
}

.pop-out-info-tab-contact
{
  user-select: auto;
  font-family: "Titillium";
  font-size: 15px;
  margin-bottom: 15px;
}

.pop-out-info-tab-contact p
{
  margin: 0px;
}

.pop-out-info-tab-contact a
{
  color: rgb(20, 160, 219);
  text-decoration: none;
}



.footer-contact
{
  text-align: left;
  font-size: 16px;
  line-height: 22px;
}

.footer-contact a
{
  color: #3caddd;
  text-decoration: none;
}

.posts-filter-button
{
  background: linear-gradient(90deg, #72bfdf 10%, #1081b1 90%);
  border: 0;
  border-radius: 48px;
  box-shadow: -5px 3px 20px -5px #72bfdf;
  color: white  !important;
  height: 48px;
  padding: 0 30px !important;
  margin: 10px !important;
}

.cdx-marker
{
  background-color: #fcf9d5;
}

.mapbox-container
{
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
}

.posts-html
{
  width: 100%;
  height: auto;
  position: relative;
  float: left;
}

.posts-html img
{
  width: 100%;
  height: auto;
  position: relative;
  float: left;
}

.service-area-description
{
  text-align: left;
}

.copyright-header
{
  width: 100%;
  text-align: center;
  margin-top: 50px;
  background-color: #000000;
  color: white;
  height: 70px;
  line-height: 70px;
}

.online-termin-container
{
  width: 100%;
  margin-top: 30px;
}

.online-termin-button
{
  width: calc(100% - 20px);
  height: 40px;
  margin: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.online-termin-stepper .MuiStepLabel-label
{
  font-family: "TitilliumBold";
  font-size: 20px;
}

.online-termin-date-text
{
  width: 100%;
  text-align: center;
  margin-top: 40px;
}

.impressum-text
{
  width: 100%;
  text-align: left;
  margin-top: 40px;
}

.error-text
{
  font-family: "TitilliumBold";
  text-align: center;
  font-size: 40px;
}

.parallax-banner-layer-0
{
  inset: 0px 0px !important;
  /*filter: blur(1px);*/
}

.MuiStepper-root
{
  padding: 0px !important;
}

.dialog-loading
{
  height: 80px;
  width: 80px;
  display:flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify-title
{
  font-family: "TitilliumBold";
  font-size: 20px;
  width: calc(100% - 20px);
  height: 30px;
  margin: 10px;
  text-align: center;
  line-height: 30px;
  user-select: none;
}

.verify-text
{
  font-family: "Titillium";
  font-size: 16px;
  width: calc(100% - 40px);
  height: auto;
  margin: 10px;
  text-align: center;
  line-height: 20px;
  user-select: none;
  margin-left: 20px;
  margin-right: 20px;
}

.verify-image-container
{
  font-family: "Titillium";
  height: 250px;
  width: calc(100% - 20px);
  margin: 10px;
  user-select: none;
  overflow: hidden;
  display: flex;
}

.verify-image
{
  height: 250px;
  width: 250px;
  margin: auto;
}

.verify-slider
{
  font-family: "Titillium";
  font-size: 16px;
  width: calc(100% - 80px);
  height: 40px;
  margin: 10px;
  margin-left: 40px;
  margin-right: 40px;
}

.verify-button
{
  margin: 20px;
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.verify-slider .MuiSlider-root
{
  background-color: #f1f1f1;
  border-radius: 30px;
}

.verify-slider .MuiSlider-rail
{
  background-color: #f1f1f1;
}

.verify-slider .MuiSlider-track
{
  background-color: #f1f1f1;
}

.verify-slider .MuiSlider-thumb
{
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 26px;
  background-position: center;
  box-shadow: 0 0px 20px 0 rgba(82, 82, 82, 0.2);
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg t="1590338601818" fill="purple" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6547" width="20" height="20"><path d="M512.299934 1023.800044c-10.797617 0-21.595234-3.999117-29.993381-11.797396-17.496139-16.496359-18.195984-44.090269-1.799602-61.586408l412.508958-437.10353c8.398147-8.898036 8.298169-23.894726-0.599868-32.692784L481.606708 74.409578c-17.096227-16.896271-17.296183-44.490181-0.299934-61.586408 16.896271-16.896271 44.390203-17.196205 61.586408-0.299934l410.809333 406.11037c42.290666 41.790777 43.590379 111.075485 2.699404 154.465909l-412.508958 437.003552c-8.69808 9.097992-20.195543 13.696977-31.593027 13.696977z" p-id="6548"></path><path d="M86.093999 924.821889c-10.697639 0-21.495256-3.999117-29.793425-11.897374-17.496139-16.496359-18.295962-44.090269-1.799603-61.586408l315.930274-334.626147c8.398147-9.097992 8.298169-24.094682-0.599868-32.792762L55.500751 173.587689c-16.996249-16.896271-17.196205-44.490181-0.299934-61.686386 16.896271-16.996249 44.390203-17.296183 61.586408-0.199956L431.017873 422.032856c42.290666 41.790777 43.490402 111.075485 2.799382 154.465909l-315.930273 334.626147c-8.69808 9.097992-20.195543 13.696977-31.792983 13.696977z" p-id="6549"></path></svg>');
}

.ql-indent-1
{
  padding-left: 3em;
}

.ql-indent-2
{
  padding-left: 6em;
}

.ql-indent-3
{
  padding-left: 9em;
}

.collapse-row *
{
  border: unset !important;
}

.footer-box-external-link
{
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 30px 1fr;
}

.footer-box-external-link-image
{
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.footer-box-external-link-text
{
  height: 20px;
  text-align: left;
  line-height: 20px;
}

.mapboxgl-ctrl-bottom-right
{
  bottom: 100px !important;
  right: 30px !important;
}

.popout-info-time
{
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 5px;
}

.popout-info-time-name
{
  padding-left: 5px;
  padding-right: 15px;
  text-align: left;
  font-size: 15px;
}

.popout-info-time-time
{
  padding-left: 5px;
  padding-right: 15px;
  text-align: center;
  font-size: 15px;
  font-family: "Titillium";
}

.pop-out-info-tab-title
{
  width: 100%;
  height: 20px;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
  margin-top: 5px;
  font-family: "TitilliumBold";
  user-select: none;
}

.pop-out-info-tab-maps
{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pop-out-info-tab-maps-text
{
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
  user-select: none;
  font-size: 15px;
  font-family: "Titillium";
}

.pop-out-info-tab
{
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 50px;
  height: 144px;
}

.pop-out-info-tab .MuiTab-root
{
  min-width: 50px !important;
}

.pop-out-info-tabpanel
{
  width: 230px;
  height: auto;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 10px;
}

.pop-out-box
{
  position: fixed;
  height: auto;
  width: 50px;
  background-color: #ffffff;
  z-index: 800;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  transform: translate(0px, 50%);
  min-height: 50px;
  top:50%;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.footer-box img
{
  max-width: 100%;
}

.news-slider-image
{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-slider-container
{
  width: 100%;
  height: 100%;
}

.news-slider-container .react-slideshow-container
{
  width: 100%;
  height: 100%;
}

.react-slideshow-wrapper.slide
{
  height: 100% !important;
}

.react-slideshow-wrapper .images-wrap
{
  height: 100% !important;
}

.react-slideshow-container .nav.default-nav
{
  position: absolute;
  margin: 20px;
}

.news-slider
{
  position: relative;
  left: 0px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 100vw;
  height: 100vh;
}

.news-slider-prev
{
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.news-slider-next
{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.slider-inner-container
{
  width: 100%;
  height: 100%;
}

.slider-title-container
{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.slider-title
{
  height: auto;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  transform: translate(0,-50%);
  text-align: center;
  top:50%;
  width: calc(100vw - 20px);
  margin-left: 10px;
  margin-right: 10px;
  position: absolute;
  text-shadow: 2px 2px 5px black;
  color: white;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  font-family: "TitilliumBold";
}
