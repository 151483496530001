.menu-logo
{
  text-align: center;
  line-height: 64px;
  overflow: hidden;
  font-size: 25px;
  font-weight: bold;
  font-family: helvetica;
  width: 100%;
  height: 100%;
  user-select: none;
}

.main-screen
{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
	grid-template-rows: 64px 1fr;
  top:0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  background-color: #f1f1f3;
  font-family: "Titillium";
}

.main-content
{
  position: relative;
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 200px 1fr;
}

.main-page
{
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  float: left;
}

.bread-crumbs
{
  position: relative;
  float: left;
  height: 24px;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 5px;
}

.main-page-content
{
  position: absolute;
  top:115px;
  left: 0px;
  height: calc(100% - 115px);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.main-work-page
{
  display: grid;
  grid-template-rows: 60px 25px 1fr;
}

.loading-bar
{
  position: absolute;
  width: 100%;
}

.main-menu
{
  top:0px;
  left: 0px;
  height: 100%;
  background-color: #2a2d30;
}

.main-menu *
{
  color: white;
}


.dashboard-hello
{
  position: relative;
  float: left;
  height: auto;
  min-height: 120px;
  text-align: left;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr;
  user-select: none;
}

.dashboard-hello-text
{
  height: auto;
  font-size: 65px;
  text-align: left;
}


.settings-table
{
  width: calc(100% - 50px) !important;
  margin-left: 25px;
  margin-right: 25px;
}

.settings-table *
{
  font-size: 16px;
  font-family: "Titillium";
}

.MuiTableCell-root
{
  font-size: 16px !important;
  font-family: "Titillium" !important;
}

.settings-title
{
  height: 30px;
  font-size: 20px;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 5px;
  margin-bottom: 15px;
  user-select: none;
  text-align: left;
}

.settings-subtitle
{
  height: 20px;
  font-size: 16px;
  line-height: 20px;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 30px;
  margin-bottom: 10px;
  user-select: none;
  text-align: left;
}

.table-buttons
{
  display: grid;
}

.small-butti
{
  position: relative;
  float: left;
  height: 25px;
  line-height: 25px;
  margin: 1px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.small-butti-green
{
  background-color: #3d8500;
  color: white;
}

.small-butti-red
{
  background-color: #9c1613;
  color: white;
}

.image-upload-status
{
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  height: auto;
  line-height: 22px;
  font-size: 16px;
  font-family: "Titillium";
}

.image-upload
{
  height: 50px;
  width: 100%;
  position: relative;
  float: left
}

.image-upload-display
{
  position: absolute;
  top:0px;
  left: 0px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  z-index: 1;
  border: 2px dashed lightgray;
  border-radius: 10px;
  text-align: center;
  line-height: 46px;
  font-family: "Titillium";
  user-select: none;
}

.image-upload-input
{
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.title-bar
{
  position: relative;
  float: left;
  height: 50px;
  margin-top: 10px;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 50px;
}

.title-bar-name
{
  height: 40px;
  text-align: left;
  font-size: 40px;
  font-weight: bolder;
  line-height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  user-select: none;
  font-family: "TitilliumBold";
}

.title-bar-icon
{
  height: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 30px;
}

.title-bar-button
{
  height: 48px;
  width: 48px;
  margin: 1px;
}

.top-bar
{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 64px;
  background-color: #2a2d30;
  display: grid;
  grid-template-columns: 200px 1fr 64px;
}

.top-bar-logo-container
{
  height: 64px;
}

.top-bar-logo
{
  width: 100%;
  height: 100%;
  position: relative;
  float: left;
}

.top-bar-logout
{
  width: 64px;
  height: 48px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: white;
}

.top-bar-logout *
{
  color: white;
}

.ColorPicker-MuiBox-root
{
  width: 350px !important;
  padding: 10px !important;
}

.site-line-input
{
  height: auto;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.site-line-text
{
  height: auto;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.settings-image-upload
{
  height: 60px;
  width: 100%;
}


.working-hours
{
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-right: 25px;
}

.working-hours .header
{
  font-size: 16px;
  font-weight: bold;
  width: 20px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  user-select: none;
}

.working-hours .day-name
{
  user-select: none;
  text-align: left;
  padding-right: 10px;
}

.working-hours .part
{
  width: 15px !important;
}

.working-hours .part span
{
  font-size: 10px !important;
  display: flex;
  line-height: 12px;
  height: 28px;
}

.working-hours .half
{
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(230,230,230,1) !important;
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(230,230,230,1) !important;
  box-shadow: inset 0px 0px 0px 1px rgba(230,230,230,1) !important;
}

.working-hours .time-cell
{
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(219,219,219,1);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(219,219,219,1);
  box-shadow: inset 0px 0px 0px 1px rgba(219,219,219,1);
  cursor: pointer;
  border: 0px;
}

.working-hours .selected
{
  background-color: lightgray;
}
